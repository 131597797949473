import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import konelogo from "../Components/kone-logo-blue.png";
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import axios from 'axios';
//import { cognitoConfig } from './Components/config';

const Login = () => {
  const HandleSSOClick = (event) => {
    event.preventDefault();
    const url = `https://${cognitoConfig.Domain}.auth.${cognitoConfig.Region}.amazoncognito.com/login?response_type=code&client_id=${cognitoConfig.ClientId}&redirect_uri=${cognitoConfig.RedirectUriSignIn}&identity_provider=${cognitoConfig.IdentityProvider}`;
    window.location.href = url;

    //const user = new CognitoUser({ Username: email, Pool: userPool });

    //const authDetails = new AuthenticationDetails({ Username: email, Password: password, });

    /*user.authenticateUser(authDetails, {
      onSuccess: (data) => {console.log("success: ", data);},
      onFailure: (err) => {console.err("failure: ", err);},
      newPasswordRequired: (data) => {console.log("new pw required: ", data)},
    })*/
    /*useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        const handleAuthResponse = async () => {
          try {
              //const url2 = `https://${cognitoConfig.domain}.auth.${cognitoConfig.region}.amazoncognito.com/oauth2/token?grant_type=authorization_code&`;
              const url2 = `https://${cognitoConfig.Domain}.auth.${cognitoConfig.Region}.amazoncognito.com/oauth2/token`;
              const response = await axios.post(url2, {
              code,
              redirect_uri: cognitoConfig.RedirectUriSignIn,
              client_id: cognitoConfig.ClientId,
            });
            const { id_token, access_token } = response.data;
            // Store tokens and handle authenticated state
            localStorage.setItem('idToken', id_token);
            localStorage.setItem('accessToken', access_token);
            //history.push('/');
            //console.log(response.data);
            console.error('2at TRY level !!!!!!!!!!!!!!!!!!!!');
          } catch (error) {
            console.error('2Error handling auth response:', error);
          }
          console.error('2at HANDLER level !!!!!!!!!!!!!!!!!!!!');
        }; handleAuthResponse();
        console.error('2at IF level !!!!!!!!!!!!!!!!!!!!');
      }
      else {
        console.error('2No code found in the callback URL');
      }     
    }, []);*/
  };

  const Navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log('URL: ', window.location.search);
    const code = urlParams.get('code');
    if (code) {
      const handleAuthResponse = async () => {
        try {
            //const url2 = `https://${cognitoConfig.domain}.auth.${cognitoConfig.region}.amazoncognito.com/oauth2/token?grant_type=authorization_code&`;
            const url2 = `https://${cognitoConfig.Domain}.auth.${cognitoConfig.Region}.amazoncognito.com/oauth2/token`;
            const response = await axios.post(url2, {
            code,
            redirect_uri: cognitoConfig.RedirectUriSignIn,
            client_id: cognitoConfig.ClientId,
          });
          const { id_token, access_token } = response.data;
          // Store tokens and handle authenticated state
          localStorage.setItem('idToken', id_token);
          localStorage.setItem('accessToken', access_token);
          Navigate('/');
          console.log(response.data);
          console.error('at TRY level !!!!!!!!!!!!!!!!!!!!');
        } catch (error) {
          console.error('Error handling auth response:', error);
        }
        console.error('at HANDLER level !!!!!!!!!!!!!!!!!!!!');
      }; handleAuthResponse();
      console.error('at IF level !!!!!!!!!!!!!!!!!!!!');
    }
    else {
      console.error('No code found in the callback URL: ', window.location.search);
    }     
  }, []);

  return(
    <div className="loginbg_minibox">
      <div id="form-wrapper">
        <form id="idp-login">
          <img className="cclogo" src={konelogo} alt="KONE" />
          <p className="title">DXS</p>
          <p className="title2">TinyManager</p>
          <div className="sso-btn">
            {/*<a href="login()"> <div class="loginsso"> <span>Login with KONE account</span> </div> </a>*/}
            <button className="loginsso" type='button' onClick={HandleSSOClick} title="KONE SSO">Login with KONE account</button>
          </div>
          <p className="versionstr">v1.0</p>
        </form>
      </div>
    </div>
  );
};

const cognitoConfig = {
  Domain: 'tinymgr-qa',
  Region: 'eu-west-1',
  UserPoolId: 'eu-west-1_wlGHGBI93',
  ClientId: '3lqp5jgr60hogedqkmjv3pueq3',
  //IdentityProvider: 'urn:amazon:cognito:sp:eu-west-1_wlGHGBI93',
  IdentityProvider: 'tinymanager-kone-sso-qa',
  //RedirectUriSignIn: 'https://tinymgr-qa.auth.eu-west-1.amazoncognito.com/saml2/idpresponse',
  RedirectUriSignIn: 'https://tinymgr-qa-dps.kone.com/home',
  //RedirectUriSignIn: 'http://localhost:3000/home',
  RedirectUriSignOut: 'https://tinymgr-qa-dps.kone.com/?',
};

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
});

export const signIn = (username, password) => {
  const user = new CognitoUser({ Username: username, Pool: userPool });
  // Implement sign-in logic
};

export const signOut = () => {
  const user = userPool.getCurrentUser();
  if (user) { user.signOut(); }
};

export default Login;