import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './LoginPage/Login';
import Home from './HomePage/Home';
import NoPage from './Components/NoPage';

export default function MainApp() {
  return (
    <div className="redirect">
      <BrowserRouter>
        <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
