import React from 'react';
//import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  //const { logout } = useAuth();
  const Navigate = useNavigate();

  const handleLogout = () => {
    //logout();
    Navigate('/login');
  };

  return (
    <div>
      <h1>Home Page</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Home;